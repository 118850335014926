
export default {
    fetchOnServer: true,
    props: {
        route: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            pages: [],
            tags: [],
            filter: [],
            pageSize: 10,
            total: 0,
            currentPage: 1,
            singlePage: null,
            prev: null,
            next: null,
            heroBanner: null,
            indexBreadCrumbs: {
                crumbs: [
                    {
                        category_name: this.$t('blog.breadcrumbs.index'),
                        category_url_path: '/',
                        directLink: true,
                    },
                ],
                product: this.$t('blog.breadcrumbs.blog'),
            },
            articleBreadCrumbs: null,
        };
    },
    async fetch() {
        if (this.$config.IS_HANSEL && !this.$storage.getCookie('medituote-catalog')) {
            this.$nuxt.context.redirect({ path: '/' });
            return;
        }

        if (this.isIndexPage) {
            if (this.$route.query.pageSize) {
                this.pageSize = parseInt(this.$route.query.pageSize);
            }

            if (this.$route.query.currentPage) {
                this.currentPage = parseInt(this.$route.query.currentPage);
            }

            if (this.$route.query.tag) {
                this.filter = [this.$route.query.tag];
                const query = Object.assign({}, this.$route.query);
                delete query.tag;
                await this.$router.replace({ query });
            }

            const tags = await this.$strapi.getEntries({
                contentType: 'blog-tags',
            });

            if (tags.data && tags.data.length) {
                this.tags = tags.data.map((tag) => tag.attributes.label);
            }

            const filters = this.filter.length
                ? {
                      tags: {
                          label: {
                              $contains: this.filter[0],
                          },
                      },
                  }
                : undefined;

            const articles = await this.$strapi.getEntries({
                contentType: 'blog-articles',
                pagination: {
                    pageSize: this.pageSize,
                    page: this.currentPage,
                },
                sort: 'publishedAt:desc',
                filters,
                populate: ['image', 'tags'],
            });

            this.total = articles.meta.pagination.pageCount;
            this.pages = articles.data;

            const heroBanners = await this.$strapi.getEntries({
                contentType: 'hero-banners',
                filters: {
                    scope: {
                        $eq: '/ajankohtaista',
                    },
                },
                populate: ['image'],
            });
            if (heroBanners.data && heroBanners.data.length) {
                [this.heroBanner] = heroBanners.data;
            }
        } else {
            const article = await this.$strapi.getEntries({
                contentType: 'blog-articles',
                filters: {
                    urlKey: {
                        $containsi: this.route,
                    },
                },
                populate: ['image', 'tags', 'meta', 'meta.ogImage'],
            });

            if (article.data && article.data.length === 1) {
                [this.singlePage] = article.data;

                const next = await this.$strapi.getEntries({
                    contentType: 'blog-articles',
                    pagination: {
                        pageSize: 1,
                        page: 1,
                    },
                    filters: {
                        publishedAt: {
                            $gt: article.data[0].attributes.publishedAt,
                        },
                    },
                    sort: 'publishedAt:asc',
                });

                if (next.data && next.data.length === 1) {
                    [this.next] = next.data;
                }

                const prev = await this.$strapi.getEntries({
                    contentType: 'blog-articles',
                    pagination: {
                        pageSize: 1,
                        page: 1,
                    },
                    filters: {
                        publishedAt: {
                            $lt: article.data[0].attributes.publishedAt,
                        },
                    },
                    sort: 'publishedAt:desc',
                });

                if (prev.data && prev.data.length === 1) {
                    [this.prev] = prev.data;
                }

                const tags = article.data[0].attributes.tags;
                if (tags && tags.data.length) {
                    const indexCrumb = {
                        category_name: this.$t('blog.breadcrumbs.index'),
                        category_url_path: '/',
                        directLink: true,
                    };
                    const blogCrumb = {
                        category_name: this.$t('blog.breadcrumbs.blog'),
                        category_url_path: '/ajankohtaista',
                        directLink: true,
                    };
                    const tagCrumb = {
                        category_name: tags.data[0].attributes.label,
                        category_url_path: `/ajankohtaista?tag=${tags.data[0].attributes.label}`,
                        directLink: true,
                    };
                    const articleTitle = article.data[0].attributes.title;

                    this.articleBreadCrumbs = {
                        crumbs: [indexCrumb, blogCrumb, tagCrumb],
                        product: articleTitle,
                    };
                }
            } else {
                this.$nuxt.error({ statusCode: 404, message: 'Article not found' });
                throw new Error('Article not found');
            }
        }
    },
    head() {
        const head = {};
        if (this.singlePage && this.singlePage.attributes.meta) {
            const metaFields = this.singlePage.attributes.meta.data.attributes;
            head.meta = [];
            if (metaFields?.title) {
                head.meta.push({
                    hid: 'title',
                    name: 'title',
                    content: metaFields.title,
                });
            }
            if (metaFields?.ogTitle) {
                head.meta.push({
                    hid: 'og:title',
                    name: 'og:title',
                    content: metaFields.ogTitle,
                });
            }
            if (metaFields?.ogDescription) {
                head.meta.push({
                    hid: 'og:description',
                    name: 'og:description',
                    content: metaFields.ogDescription,
                });
                head.meta.push({
                    hid: 'description',
                    name: 'description',
                    content: metaFields.ogDescription,
                });
            }
            if (metaFields?.keywords) {
                head.meta.push({
                    hid: 'keywords',
                    name: 'keywords',
                    content: metaFields.keywords,
                });
            }
            if (metaFields?.ogImage?.data?.attributes?.url) {
                head.meta.push({
                    hid: 'og:image',
                    name: 'og:image',
                    content: metaFields.ogImage.data.attributes.url,
                });
            }
        }

        if (this.singlePage) {
            head.title = this.singlePage.attributes.title;
        } else {
            head.title = this.$t('Blog:Blog');
        }

        return head;
    },
    computed: {
        isIndexPage() {
            return this.route.toLowerCase().replaceAll('/', '') === 'ajankohtaista';
        },
    },
    watch: {
        '$route.query'() {
            this.$fetch();
        },
    },
    methods: {
        clearFilters() {
            this.filter = [];
            this.$fetch();
        },
        addToFilters(filterLabel) {
            this.filter = [filterLabel];
            this.$fetch();
        },
        onPaginationUpdate(index) {
            const { query } = this.$route;
            delete query.currentPage;
            this.currentPage = index;
            this.$router.push({ path: this.$route.path, query: { currentPage: index, ...query } });
            this.$fetch();
        },
    },
};
